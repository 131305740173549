import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import getAxios from 'common/axios';
const axios = getAxios();

const REACT_APP_SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

export const fetchAttendeeProducts = createAsyncThunk(
  'products/fetchAttendeeProducts',
  async ({ showId, orgId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${REACT_APP_SERVER_BASE_URL}/floorplan/products/attendee/${showId}/${orgId}`, {
        withCredentials: true
      });
      return data.products;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchSponsorshipProducts = createAsyncThunk(
  'products/fetchSponsorshipProducts',
  async ({ showId, orgId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${REACT_APP_SERVER_BASE_URL}/floorplan/products/sponsorship/${showId}/${orgId}`, {
        withCredentials: true
      });
      return data.products;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const productsSlice = createSlice({
  name: 'products',
  initialState: {
    attendeeProducts: [],
    sponsorshipProducts: [],
    attendeeProductsStatus: 'idle',
    sponsorshipProductsStatus: 'idle',
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Attendee Products
      .addCase(fetchAttendeeProducts.pending, (state) => {
        state.attendeeProductsStatus = 'loading';
        state.error = null;
      })
      .addCase(fetchAttendeeProducts.fulfilled, (state, action) => {
        state.attendeeProductsStatus = 'succeeded';
        state.attendeeProducts = action.payload;
      })
      .addCase(fetchAttendeeProducts.rejected, (state, action) => {
        state.attendeeProductsStatus = 'failed';
        state.error = action.payload;
      })
      // Sponsorship Products
      .addCase(fetchSponsorshipProducts.pending, (state) => {
        state.sponsorshipProductsStatus = 'loading';
        state.error = null;
      })
      .addCase(fetchSponsorshipProducts.fulfilled, (state, action) => {
        state.sponsorshipProductsStatus = 'succeeded';
        state.sponsorshipProducts = action.payload;
      })
      .addCase(fetchSponsorshipProducts.rejected, (state, action) => {
        state.sponsorshipProductsStatus = 'failed';
        state.error = action.payload;
      })
  }
});

export default productsSlice.reducer;
