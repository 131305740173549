import React from 'react';
import {Nav, Button, Dropdown} from "react-bootstrap";
import {NavLink, useLocation} from 'react-router-dom';
import GoogleTranslate from 'components/GoogleTranslate';
import {useSelector} from 'react-redux';
import {getShowAbbreviation} from 'common/index';
import {validateCurrentUser, validateExhibitorUser} from 'components/utils';
import {EXHIBITOR} from 'constants';

const AuthNavigation = ({handleMobileNavClose}) => {

  const user = useSelector(state => state.currentUser);
  const showAbbr = getShowAbbreviation();
  const availableOnExhibitorPortal = useSelector(state => state.show?.availableOnExhibitorPortal) || false;
  const showUnpublished = useSelector(state => state.show?.showUnpublished) || false;

  const location = useLocation();

  // Check if any of the dropdown items are active
  const isDropdownActive =
    location.pathname.includes(`/${showAbbr}/profile`) ||
    location.pathname.includes(`/${showAbbr}/myshow`) ||
    location.pathname.includes(`/${showAbbr}/my-products`) ||
    location.pathname.includes(`/${showAbbr}/logout`);

  const handleDropdownClick = () => {
    if (handleMobileNavClose) {
      handleMobileNavClose();
    }
  }

  if (user === null || user._id === null) {
    return (
      <div className="navbar-login-links" onClick={handleDropdownClick}>
        {(!showUnpublished) && (
          <NavLink eventkey="5" to={`/${showAbbr}/login`} state={{from: location}} className="nav-link w-100">
            {/* {EXHIBITOR}  */}
            Login
          </NavLink>
        )}
        {/* {!showUnpublished && (
          <NavLink eventkey="6" to={`/${showAbbr}/login`} state={{from: location}} className="nav-link">
            Attendee Login
          </NavLink>
        )} */}
        {/* {!validateCurrentUser(user) && (<GoogleTranslate />)} */}
      </div>
    );
  }
  else {
    let userFirstName = user.firstName;
    return (
      <Dropdown as="div" className={`profileDropdown nav-link ${isDropdownActive ? 'navLinkActive' : ''}`}>
        <Dropdown.Toggle as="div" id="dropdown-autoclose-true">
          <div className="profile-dropdown">
            <div className="profile-name">{userFirstName}</div>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mainUserDropdownMenu" onClick={handleDropdownClick}>
          <Dropdown.Item as="button">
            <NavLink eventkey="9" to={`/${showAbbr}/profile`} className="nav-link">
              Profile
            </NavLink>
          </Dropdown.Item>
          {/* <Dropdown.Item as="button">
            <NavLink eventkey="6" to={`/${showAbbr}/myshow`} className="nav-link">
              My Show Planner
            </NavLink>
          </Dropdown.Item> */}
          {/* {
            validateExhibitorUser(user) && (
              <Dropdown.Item as="button">
                <NavLink eventkey="7" to={`/${showAbbr}/my-products`} className="nav-link">
                  My Products
                </NavLink>
              </Dropdown.Item>
            )
          } */}
          <Dropdown.Item as="button">
            <NavLink eventkey="8" to={`/${showAbbr}/logout`} className="nav-link">
              Logout
            </NavLink>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
};

export default AuthNavigation;
